

































































































































































































































































































































































































































































import { CommonListRes, Page, CommonRes } from "@/api/common";
import FaultBoard from "@/components/FaultBoard.vue";
import MaintainBoard from "@/components/MaintainBoard.vue";
import RepairBoard from "@/components/RepairBoard.vue";
import MessageBoard from "@/components/MessageBoard.vue"
import FaultFloatButton from "@/components/FaultFloatButton.vue";
import { Toast, Dialog } from "vant";
import { User } from "@/api/user";
import {
  getFaultList,
  Fault,
  deleteFault,
  GetFaultListReq
} from "@/api/fault";
import {
  deleteMaintain,
  getMaintainList,
  Maintenance,
  GetMaintenanceListReq
} from "@/api/maintenance";
import {
  deleteRepair,
  getRepairList, Repair
} from "@/api/repair";
import {
  getMessage, Message, Messageeq
} from "@/api/message";
import { Component, Vue } from "vue-property-decorator";
import { formatDate } from "@/utils/utils";
// import 'vant/lib/tabs/style/less';
import {
  getRobotList,
  GetRobotListReq,
  Robot,
  getRobotStyleList,
  RobotStyle
} from "@/api/robot";
 
interface textStatus {
  isSelect: boolean;
  text: string;
  value: string;
}

interface filterList {
  all: boolean;
  title: string;
  icon: string;
  list: textStatus[];
  searchText: string;
}
@Component({
  components: {
    FaultBoard,
    FaultFloatButton,
    MessageBoard,
    MaintainBoard,
    RepairBoard
  },
})
export default class Index extends Vue {
  private faultList: Fault[] = [];
  private maintainList: Maintenance[] = [];
  private repairList: Repair[] = [];
  private faultListAll: Fault[] = [];
  private mainListAll: Maintenance[] = []; 
  private repairListAll: Repair[] = [];                    
  private messageList: Message[] = [];
  private limit: number = 1000;
  private type: number = 0;

  private refreshing: boolean = false;
  private refreshingM: boolean = false;
  private refreshingR: boolean = false;
  private refreshingMsg: boolean = false;
  private loading: boolean = false;
  private loadingMsg: boolean = false;
  private loadingM: boolean = false;
  private loadingR: boolean = false;
  private finished: boolean = false;
  private finishedM: boolean = false;
  private finishedMsg: boolean = false;
  private finishedR: boolean = false;
  private show: boolean = false;
  private showM: boolean = false;
  private showR: boolean = false;
  private showDateTime: boolean = false;
  private startTime: string = "";
  private startTimeM: string = "";
  private startTimeR: string = "";
  private stopTime: string = "";
  private stopTimeM: string = "";
  private stopTimeR: string = "";
  private state: string = "";
  private robotName: string = "";
  private robotNameM: string = "";
  private robotNameR: string = "";
  private startTimeBegin: string = "";
  private currentDate: Date = new Date();
  private minDate: Date = new Date(2000, 0, 1);
  private maxDate: Date = new Date(2035, 10, 1);
  private scroll: number = 0;
  private userInfoStr: string | null = localStorage.getItem("userInfo")
  private userInfo!: User;
  private isPuase: boolean = false;
  private active: number = 0;
  private activeR: number = 0;
  private radiohVar = '20px';
  private badge: string = '';
  private rlength: number = 0;
  private rlengthM: number = 0;
  private rlengthR: number = 0;
  private searchText: string = '';
  private searchTextM: string = '';
  private searchTextR: string = '';
  private fiterMapNameMsg: textStatus[] = [];
  private fiterMapName: textStatus[] = [];
  private fiterMapNameM: textStatus[] = [];
  private fiterMapNameR: textStatus[] = [];
  private robotStyleList : RobotStyle[] = [];
  private zwFlag:boolean = true;
  private zwFlagM:boolean = true;
  private zwFlagR:boolean = true;
  private setList: filterList[] = [
    {
      all: false,
      title: this.$t("detailStatus").toString(),
      icon: require("@/assets/img/cleaning.png"),
      list: [
        { isSelect: false, text: this.$t("undetail").toString(), value: "0" },
        { isSelect: false, text: this.$t("confirmed").toString(), value: "1" },
        { isSelect: false, text: this.$t("resolved").toString(), value: "2" }
      ],
      searchText: ''
    },
    {
      all: false,
      title: this.$t("robotLabel").toString(),
      icon: require("@/assets/img/robot1.png"),
      list: [],
      searchText: ''
    },
  ];
  private setListM: filterList[] = [
    {
      all: false,
      title: this.$t("robotLabel").toString(),
      icon: require("@/assets/img/robot1.png"),
      list: [],
      searchText: ''
    },
  ];
  private setListR: filterList[] = [
    {
      all: false,
      title: this.$t("robotLabel").toString(),
      icon: require("@/assets/img/robot1.png"),
      list: [],
      searchText: ''
    },
  ];
  private async deleteItem (item: any) {
    if(this.userInfo.username === item.noteTaker || this.userInfo.userType > 1){
      console.log('deleteItem', 'deleteItem:' + item)
      let res: CommonRes = await deleteFault([item.id]);
      if( res.code === 0){
        this.getFaultList()
        Toast.success(this.$t("successfully_deleted"))
      }
    } else {
      Toast.success(this.$t("submitter_can_delete"))
    }
  }
  private async deleteItemM (item: any) {
    if(this.userInfo.userType > 1){
      console.log('deleteItem', 'deleteItem:' + item)
      let res: CommonRes = await deleteMaintain([item.id]);
      if( res.code === 0){
        this.getMaintainList()
        Toast.success(this.$t("successfully_deleted"))
      }
    } else {
      Toast.success(this.$t("submitter_can_delete"))
    }
  }
  private async deleteItemR (item: any) {
    if(this.userInfo.userType > 1){
      console.log('deleteItem', 'deleteItem:' + item)
      let res: CommonRes = await deleteRepair([item.id]);
      if( res.code === 0){
        this.getRepairList()
        Toast.success(this.$t("successfully_deleted"))
      }
    } else {
      Toast.success(this.$t("submitter_can_delete"))
    }
  }
  private  editItem(item: any, index: any){
    localStorage.setItem("refresh", "true");
    if(this.active == 0) {
      if(this.userInfo.userType > 1 || this.userInfo.username === item.noteTaker){
        console.log('editItem', 'editItem:' + index)
        this.$router.push({
          path: "/newFault",
          name: "newFault",
          params: {faultdetail: JSON.stringify(this.faultList[index])},
        });
      } else {
        Toast.success(this.$t("submitter_and_admin_can_delete"))
      }
    } else if(this.active == 1) {
      // maintainDetail
      if( this.userInfo.userType > 1){
        console.log('editItem', 'editItem:' + index)
        console.log('maintainList入口', this.maintainList[index])
        this.$router.push({
          path: "/newMaintain",
          name: "newMaintain",
          params: {maintaindetail: JSON.stringify(this.maintainList[index])},
        });scroll
      } else {
        Toast.success(this.$t("admin_can_delete"))
      }
    } else if(this.active == 2) {
      // maintainDetail
      if(this.userInfo.userType > 1){
        console.log('editItem', 'editItem:' + index)
        this.$router.push({
          path: "/newRepair",
          name: "newRepair",
          params: {repairDetail: JSON.stringify(this.repairList[index])},
        });scroll
      } else {
        Toast.success(this.$t("admin_can_delete"))
      }
    }
  }
  
  private messagereq : Messageeq = {
      page: 1,
      limit: 20,
      timeZone: ''
    }
  private req: GetFaultListReq = {
    page: 1,
    limit: this.limit,
    state: '0,1'
  }
  private reqM: GetMaintenanceListReq = {
    page: 1,
    limit: this.limit
  }
  private reqR: GetMaintenanceListReq = {
    page: 1,
    limit: this.limit
  }
  mounted() {
    this.getUndetailNum();
    window.addEventListener("scroll", this.handleScroll, true);
  }

  destroyed() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  handleScroll() {
    if (!this.isPuase) {
      const $content = document.querySelector(".faultBox");
      const scrollTop = $content ? $content.scrollTop : 0;
      this.scroll = scrollTop;
      console.log("handleScroll this.scroll:" + this.scroll + " window.pageYOffset:" + window.pageYOffset + " document.documentElement.scrollTop :" + document.documentElement.scrollTop + "document.body.scrollTop:" + document.body.scrollTop );
    }
 }
 private checkSearchTitleText() {
    if (this.zwFlag) {
      if (this.searchText != "") {
        this.rlength = 0;
        this.faultList = this.filterTitleList(this.faultListAll, this.searchText);
      } else {
        this.onRefresh();
      }
    }
  }
  
  private compositionstart() {
    this.zwFlag = false;
    console.log("compositionstart")
  }
  private compositionstartM() {
    this.zwFlagM = false;
    console.log("compositionstart")
  }
  private compositionstartR() {
    this.zwFlagR = false;
    console.log("compositionstart")
  }
  private compositionendM() {
    this.zwFlagM = true
    console.log("compositionend")
  }
  private compositionendR() {
    this.zwFlagR = true
    console.log("compositionend")
  }
  private compositionend() {
    this.zwFlag = true
    console.log("compositionend")
  }
  private checkSearchTitleTextM() {
    console.log(" searchTextM:" + this.searchTextM)
    if(this.zwFlagM) {
      if (this.searchTextM != "") {
        this.rlengthM = 0;
        this.maintainList = this.filterTitleListM(this.mainListAll, this.searchTextM);
      } else {
        console.log('searchTextM onRefreshM')
        this.onRefreshM();
      }
    }
  }
  private checkSearchTitleTextR() {
    if (this.zwFlagR) {
      if (this.searchTextR != "") {
        this.rlengthR = 0;
        this.repairList = this.filterTitleListR(this.repairListAll, this.searchTextR);
      } else {
        this.onRefreshR();
      }
    }
  }
  private filterTitleList(list: Fault[], text: string) {
    console.log('filterList:', list)
    return list.filter((item: Fault) => this.checkTitle(item, text));
  }
  private filterTitleListM(list: Maintenance[], text: string) {
    console.log('MaintainList:', list)
    return list.filter((item: Maintenance) => this.checkTitleM(item, text));
  }
  private filterTitleListR(list: Repair[], text: string) {
    console.log('filterList:', list)
    return list.filter((item: Repair) => this.checkTitleR(item, text));
  }
  private checkTitle(fault: Fault, text: string) {
    if ((fault.title.toLowerCase().indexOf(text.toLowerCase()) > -1) && this.rlength < 20) {
      this.rlength++;
      return true;
    } else {
      return false;
    }
  }
   private checkTitleM(maintenance: Maintenance, text: string) {
    console.log('MaintainList', 'maintenance.checkedBy:' + maintenance.checkedBy +  'text:' + text + " rlengthM:" + this.rlengthM + " indexOf:" + (maintenance.checkedBy.toLowerCase().indexOf(text.toLowerCase())))
    if ((maintenance.checkedBy.toLowerCase().indexOf(text.toLowerCase()) > -1 || maintenance.companyName.toLowerCase().indexOf(text.toLowerCase()) > -1) && this.rlengthM < 20) {
       console.log('MaintainList checkTitleM true')
      this.rlengthM++;
      return true;
    } else {
      return false;
    }
  }
   private checkTitleR(repair: Repair, text: string) {
    if ((repair.checkedBy.toLowerCase().indexOf(text.toLowerCase()) > -1 || repair.companyName.toLowerCase().indexOf(text.toLowerCase()) > -1 ) && this.rlengthR < 20) {
      this.rlengthR++;
      return true;
    } else {
      return false;
    }
  }
private getScroll() {
    let top :number = 0;
    top = window.pageYOffset ||
              document.documentElement.scrollTop ||
              document.body.scrollTop ||
              0
    return top
  }
  activated () {
    this.isPuase = false
    let refresh = localStorage.getItem("refresh");
    let active = localStorage.getItem("active");
    if(active != null) {
      this.active = parseInt(active)
    } 
    console.log("activated refresh:" + refresh);
    if (refresh != null) {
      this.faultList = [];
      localStorage.removeItem("refresh");
      if (this.active == 0) {
        this.onRefresh();
      } else if (this.active == 1) {
        this.onRefreshM();
      } else if (this.active == 2) {
        this.onRefreshR();
        //this.getRepairList();
      } 
    } else {
      const $content = document.querySelector(".faultBox");
      console.log("this.scroll:" + this.scroll + " $content:" + $content)
      if (this.scroll && $content) {
        console.log("this.scroll:" + this.scroll)
        $content.scrollTop = this.scroll;
      }
    }
  }
  deactivated () {
    this.isPuase = true;
    console.log("deactivated refresh");
    localStorage.setItem("active", this.active + "");
  }
  private selectItemAll(list: filterList) {
    list.all = !list.all;
  }

  private selectItem(item: textStatus) {
    item.isSelect = !item.isSelect;
  }
  private onRefresh() {
    console.log("onRefresh");
    this.finished = false;
    this.req.page = 1;
    this.loading = true;
    this.searchText = ""
    this.onLoad();
  }
   private onRefreshMsg() {
    console.log("onRefresh");
    this.finishedMsg = false;
    this.messagereq.page = 1;
    this.loadingMsg = true;
    this.searchText = ""
    this.onLoad();
  }
   private onRefreshM () {
    console.log("onRefresh");
    this.finishedM = false;
    this.reqR.page = 1;
    this.loadingM = true;
    this.searchTextM = ""
    this.onLoadM();
  }
  private onRefreshR () {
    console.log("onRefresh");
    this.finishedR = false;
    this.reqM.page = 1;
    this.loadingR = true;
    this.searchTextR = ""
    this.onLoadR();
  }
  async created() {
    console.log("fault created");
    this.getRobotStyleList();
    if(this.userInfoStr != null) {
      this.userInfo = JSON.parse(this.userInfoStr);
    }
    let req: GetRobotListReq = {
      page: 1,
      limit: 1000,
      // name: this.searchText,
    };
    let res: CommonListRes<Page<Robot>> = await getRobotList(req);
    this.setList[1].list = [];
    this.setListM[0].list = []
    this.setListR[0].list = []
    if (res.code == 0) {
      for (let index = 0; index < res.page.list.length; index++) {
        const element = res.page.list[index];
        this.setList[1].list.push({
          isSelect: false,
          text: element.robotEntity.name,
          value: element.robotEntity.robotId
        });
        this.setListM[0].list.push({
          isSelect: false,
          text: element.robotEntity.name,
          value: element.robotEntity.robotId
        });
        this.setListR[0].list.push({
          isSelect: false,
          text: element.robotEntity.name,
          value: element.robotEntity.robotId
        });
      }
    }
    this.fiterMapName = this.setList[1].list
    this.fiterMapNameM = this.setListM[0].list
    this.fiterMapNameR = this.setListR[0].list
  }
  private onLoad() {
    console.log("onLoad", this.loading);
    if(this.activeR == 0) {
      this.faultList = []
      this.getFaultList();
    } else {
      this.getMessageList();
    }
  }
  private onLoadM() {
    console.log("onLoadM", this.loading);
    this.maintainList = []
    this.getMaintainList();
  }
  
  private onLoadR () {
    console.log("onLoadR", this.loading);
    this.repairList = []
    this.getRepairList();
  }
  private changeTabsRx () {
    if (this.activeR === 0) {
      this.onRefresh();
    } else {
      this.onRefreshMsg();
    }
  }
  private changeTabs() {
    console.log("changeTabs this.active:"+ this.active);
    if (this.active == 0) {
      this.onRefresh();
    } else if (this.active == 1) {
      this.onRefreshM();
    } else if (this.active == 2) {
      this.onRefreshR();
    }
  }
  private async getFaultList() {
    let res: CommonListRes<Page<Fault>> = await getFaultList(this.req);
    if (this.refreshing) {
      // this.faultList = [];
      this.refreshing = false;
    }
    if (res.code == 0) {
      this.faultList = [];
      this.faultList = this.faultList.concat(res.page.list);
      this.loading = false;
      console.log(this.faultList);
      
      this.faultListAll = []
      let faultListTemp: Fault[] = []
      let undeatilNum: number = 0;
      this.faultList.forEach(element => {
        faultListTemp.push(element)
        if (element.state == 0) {
          undeatilNum++
        }
      });
      this.faultListAll = faultListTemp
      // this.faultList = this.filterTitleList(this.faultListAll, this.searchText);
      if (undeatilNum == 0) {
        this.badge = "";
      } else {
        this.badge = undeatilNum + "";
      }
      if (res.page.totalPage <= this.req.page) {
        this.finished = true;
      } else {
        this.req.page++;
      }
    } else {
      this.loading = false;
      this.finished = true;
    }
  }
  // 维护报告
  private async getMaintainList() {
    let res: CommonListRes<Page<Maintenance>> = await getMaintainList(this.reqM);
    if (this.refreshingM) {
      // this.faultList = [];
      this.refreshingM = false;
    }
    if (res.code == 0) {
      this.maintainList = [];
      this.maintainList = this.maintainList.concat(res.page.list);
      this.loadingM = false;
      console.log(this.maintainList);
      
      this.mainListAll = []
      let maintainListTemp: Maintenance[] = []
      this.maintainList.forEach(element => {
        maintainListTemp.push(element)
      });
      this.mainListAll = maintainListTemp
      console.log('getMaintainList mainListAll', this.mainListAll)
      // this.maintainList = this.filterTitleListM(this.mainListAll, this.searchTextM);
      console.log('getMaintainList', this.maintainList)
      console.log('getMaintainList searchTextM', "testM:" + this.searchTextM)
      if (res.page.totalPage <= this.reqM.page) {
        this.finishedM = true;
      } else {
        this.reqM.page++;
      }
    } else {
      this.loadingM = false;
      this.finishedM = true;
    }
  }
  // 维修报告
  private async getRepairList() {
    let res: CommonListRes<Page<Repair>> = await getRepairList(this.reqR);
    if (this.refreshingR) {
      // this.faultList = [];
      this.refreshingR = false;
    }
    if (res.code == 0) {
      this.repairList = [];
      this.repairList = this.repairList.concat(res.page.list);
      this.loadingR = false;
      console.log(this.repairList);
      
      this.repairListAll = []
      let repairListTemp: Repair[] = []
      this.repairList.forEach(element => {
        repairListTemp.push(element)
      });
      this.repairListAll = repairListTemp
      // this.faultList = this.filterTitleList(this.faultListAll, this.searchText);
      if (res.page.totalPage <= this.reqR.page) {
        this.finishedR = true;
      } else {
        this.reqR.page++;
      }
    } else {
      this.loadingR = false;
      this.finishedR = true;
    }
  }
  private async getUndetailNum () {
    let res: CommonListRes<Page<Fault>> = await getFaultList(this.req);
    if (res.code == 0) {
      this.faultList = [];
      this.faultList = this.faultList.concat(res.page.list);
      console.log(this.faultList);
      let undeatilNum: number = 0;
      this.faultList.forEach(element => {
        if (element.state == 0) {
          undeatilNum++
        }
      });
      if (undeatilNum == 0) {
        this.badge = "";
      } else {
        this.badge = undeatilNum + "";
      }
    }
  }
  private async getMessageList() {
    let timeZoneF = Intl.DateTimeFormat().resolvedOptions().timeZone;
    console.log('timeZoneF', timeZoneF); // Outputs the user's time zone
    this.messagereq.timeZone = timeZoneF
    let res: CommonListRes<Page<Message>> = await getMessage(this.messagereq);
    if (this.refreshingMsg) {
      this.messageList = [];
      this.refreshingMsg = false;
    }
    if (res.code == 0) {
      this.messageList = this.messageList.concat(res.page.list);
      this.loadingMsg = false;
      console.log("onload messageList:", this.messageList);
      console.log("onload res.page.totalPage:" + res.page.totalPage + " this.req.page:" + this.messagereq.page)
      if (res.page.totalPage <= this.messagereq.page) {
        this.finishedMsg = true;
      } else {
        this.messagereq.page++;
        //this.finished = true;
      }
    } else {
      this.loadingMsg = false;
      this.finishedMsg = true;
    }
  }
  private openFilter() {
    for (let index = 0; index < this.setList.length; index++) {
      const element = this.setList[index];
      element.all = false;
    }
    this.show = true;
  }
  private openFilterM() {
    for (let index = 0; index < this.setListM.length; index++) {
      const element = this.setListM[index];
      element.all = false;
    }
    this.showM = true;
  }
  private openFilterR() {
    for (let index = 0; index < this.setListR.length; index++) {
      const element = this.setListR[index];
      element.all = false;
    }
    this.showR = true;
  }
  private clickItemEvent(itemData: any) {
    if (this.activeR == 0) {
      this.isPuase = true
      this.$router.push({
        path: "/faultDetail",
        name: "faultDetail",
        params: {faultdetail: JSON.stringify(itemData)},
      });
    } else {
      console.log('itemData', itemData)
      let datas = itemData.url.split("&nm=")
      if (itemData.messageType == 1) {
        this.isPuase = true
        this.$router.push({
          path: "/workDetail",
          name: "WorkDetail",
          params: {robotName: datas[1], robotId: itemData.robotId, id: itemData.taskId}
        });
      } else {
        let title = this.$t('system_information').toString()
        if(itemData.messageType == 2) {
          title = this.$t('alarm_information').toString()
        }
        Dialog.alert({
          title: title,
          message: itemData.robotId + '<br>' + itemData.pushTime + '<br>' + itemData.content,
          confirmButtonColor: '#5488FE'
        }).then(() => {
          // on close
        });
        // this.$router.push({
        //   path: "/workDetail",
        //   name: "WorkDetail",
        //   params: {robotName: datas[1], robotId: itemData.robotId, id: itemData.taskId}
        // });
      }
    }
  }
private clickItemEventM(itemData: any) {
    let temItem = JSON.parse(JSON.stringify(itemData))
    if (temItem.machineModel  === -1) {
       temItem.machineModel = 'YJH'
    } else {
      this.robotStyleList.forEach(element => {
        if (temItem.machineModel == element.id) {
          temItem.machineModel = element.robotStyle
        }
      });
    }
    if (this.active == 1) {
      this.isPuase = true
      this.$router.push({
        path: "/maintainDetail",
        name: "maintainDetail",
        params: {maintainDetail: JSON.stringify(temItem)},
      });
    }
  }
  
 private async getRobotStyleList() {
    let res: CommonListRes<Page<RobotStyle>> = await getRobotStyleList();
    if (res.code == 0) {
      this.robotStyleList = this.robotStyleList.concat(res.page.list);
    }
  }
  private clickItemEventR(itemData: any) {
    let temItem = JSON.parse(JSON.stringify(itemData))
    if (temItem.machineModel  === -1) {
       temItem.machineModel = 'YJH'
    } else {
      this.robotStyleList.forEach(element => {
        if(temItem.machineModel == element.id) {
          temItem.machineModel = element.robotStyle
        }
      });
    }
    console.log('repairDetail入口', temItem)
    this.isPuase = true
    this.$router.push({
      path: "/repairDetail",
      name: "repairDetail",
      params: {repairDetail: JSON.stringify(temItem)},
    });
  }
  private clickStartTime() {
    this.type = 0;
    this.showDateTime = true;
  }
  private clickStartTimeM() {
    this.type = 2;
    this.showDateTime = true;
  }
  private clickStartTimeR() {
    this.type = 4;
    this.showDateTime = true;
  }
  private clickStopTime() {
    this.type = 1;
    this.showDateTime = true;
  }
  private clickStopTimeM() {
    this.type = 3;
    this.showDateTime = true;
  }
   private clickStopTimeR() {
    this.type = 5;
    this.showDateTime = true;
  }
  private confirmDateTime() {
    this.showDateTime = false;
    console.log("confirmDateTime type", this.type)
    if (this.type == 0) {
      this.startTime = formatDate(this.currentDate.getTime() / 1000, "-", ":");
    } else if (this.type == 1){
      this.stopTime = formatDate(this.currentDate.getTime() / 1000, "-", ":");
    } else if (this.type == 2){
      this.startTimeM = formatDate(this.currentDate.getTime() / 1000, "-", ":");
    } else if (this.type == 3){
      this.stopTimeM = formatDate(this.currentDate.getTime() / 1000, "-", ":");
    } else if (this.type == 4){
      this.startTimeR = formatDate(this.currentDate.getTime() / 1000, "-", ":");
    } else if (this.type == 5){
      this.stopTimeR = formatDate(this.currentDate.getTime() / 1000, "-", ":");
    }
    // console.log("startTime：" + this.startTime + "stopTime" + this.stopTime + "startTimeM：" + this.startTimeM + "stopTimeM" + this.stopTimeM)
  }

  private cancelDateTime() {
    this.showDateTime = false;
  }

  private clickConfirm() {
    this.setReq();
    this.show = false;
    this.refreshing = true;
    // this.refreshingMsg = true;
    this.onRefresh();
  }
  private clickConfirmM() {
    this.setReqM();
    this.showM = false;
    this.refreshingM = true;
    this.onRefreshM();
  }
  private clickConfirmR() {
    this.setReqR();
    this.showR = false;
    this.refreshingR = true;
    this.onRefreshR();
  }
  private focusChange (item: filterList, type: string) {
    if (type == 'focus') {
      item.all = true
    } else if(type == 'blur') {
      // item.all = false
    }
  }
  private checkSearchText(item: filterList) {
    this.rlength = 0;
     if (item.searchText == "") {
      this.setList[1].list = this.fiterMapName;
      } else {
        this.setList[1].list = this.filterList(this.fiterMapName, item.searchText);
    }
  }
  private checkSearchTextM(item: filterList) {
    this.rlengthM = 0;
    if (item.searchText == "") {
      this.setListM[0].list = this.fiterMapNameM;
    } else {
      this.setListM[0].list = this.filterListM(this.fiterMapNameM, item.searchText);
    }
  }
  private checkSearchTextR(item: filterList) {
    this.rlengthR = 0;
    if (item.searchText == "") {
      this.setListR[0].list = this.fiterMapNameR;
    } else {
      this.setListR[0].list = this.filterListR(this.fiterMapNameR, item.searchText);
    }
  }
  private filterList(list: textStatus[], text: string) {
    return list.filter((item: textStatus) => this.check(item, text));
  }
  private filterListM(list: textStatus[], text: string) {
    return list.filter((item: textStatus) => this.checkM(item, text));
  }
  private filterListR(list: textStatus[], text: string) {
    return list.filter((item: textStatus) => this.checkR(item, text));
  }
  private check(nameText: textStatus, text: string) {
    if (
      (nameText.text.toLowerCase().indexOf(text.toLowerCase()) > -1 || nameText.value.toLowerCase().indexOf(text.toLowerCase()) > -1) &&
      this.rlength < 40
    ) {
      this.rlength++;
      return true;
    } else {
      return false;
    }
  }
   private checkM(nameText: textStatus, text: string) {
    if (
      (nameText.text.toLowerCase().indexOf(text.toLowerCase()) > -1 || nameText.value.toLowerCase().indexOf(text.toLowerCase()) > -1) &&
      this.rlengthM < 40
    ) {
      this.rlengthM++;
      return true;
    } else {
      return false;
    }
  }
   private checkR(nameText: textStatus, text: string) {
    if (
      (nameText.text.toLowerCase().indexOf(text.toLowerCase()) > -1 || nameText.value.toLowerCase().indexOf(text.toLowerCase()) > -1) &&
      this.rlengthR < 40
    ) {
      this.rlengthR++;
      return true;
    } else {
      return false;
    }
  }
  private clickReset() {
    for (let index = 0; index < this.setList.length; index++) {
      const element = this.setList[index];
      for (let index1 = 0; index1 < element.list.length; index1++) {
        const element1 = element.list[index1];
        element1.isSelect = false;
      }
    }
    this.setList[1].searchText = "";
    this.checkSearchText(this.setList[1]);
    this.startTime = "";
    this.stopTime = "";
    this.setReq();
    this.show = false;
    this.refreshing = true;
    this.onRefresh();
  }
private clickResetM() {
    for (let index = 0; index < this.setListM.length; index++) {
      const element = this.setListM[index];
      for (let index1 = 0; index1 < element.list.length; index1++) {
        const element1 = element.list[index1];
        element1.isSelect = false;
      }
    }
    this.setListM[0].searchText = "";
    this.checkSearchTextM(this.setListM[0]);
    this.startTimeM = "";
    this.stopTimeM = "";
    this.setReqM();
    this.showM = false;
    this.refreshingM = true;
    this.onRefreshM();
  }
  private clickResetR() {
    for (let index = 0; index < this.setListR.length; index++) {
      const element = this.setListR[index];
      for (let index1 = 0; index1 < element.list.length; index1++) {
        const element1 = element.list[index1];
        element1.isSelect = false;
      }
    }
    this.setListR[0].searchText = "";
    this.checkSearchTextR(this.setListR[0]);
    this.startTimeR = "";
    this.stopTimeR = "";
    this.setReqR();
    this.showM = false;
    this.refreshingM = true;
    this.onRefreshM();
  }
  private setReq() {
    this.req = {
      page: 1,
      limit: this.limit,
    };
    this.robotName = "";
    this.state = ""
    for (let index = 0; index < this.setList[0].list.length; index++) {
      const element = this.setList[0].list[index];
      if (element.isSelect) {
        this.state += index + ",";
      }
    }
    for (let index = 0; index < this.setList[1].list.length; index++) {
      const element = this.setList[1].list[index];
      if (element.isSelect) {
        this.robotName += element.text + ",";
      }
    }
    if (this.state != "") {
      this.req.state = this.state.substring(
        0,
        this.state.length - 1
      );
    } else {
      this.req.state = "0,1"
    }
    if (this.robotName != "") {
      this.req.robotName = this.robotName.substring(
        0,
        this.robotName.length - 1
      );
    }
    if (this.startTime != "") {
      this.req.startTimeBegin = this.startTime;
    }
    if (this.stopTime != "") {
      this.req.startTimeEnd = this.stopTime;
    }
  }
  private setMsgReq() {
    this.messagereq = {
      page: 1,
      limit: this.limit,
      timeZone: ''
    };
  }
  private setReqM() {
    this.reqM = {
      page: 1,
      limit: this.limit,
    };
    this.robotNameM = "";
    for (let index = 0; index < this.setListM[0].list.length; index++) {
      const element = this.setListM[0].list[index];
      if (element.isSelect) {
        this.robotNameM += element.text + ",";
      }
    }
    if (this.robotNameM != "") {
      this.reqM.robotName = this.robotNameM.substring(
        0,
        this.robotNameM.length - 1
      );
    }
    if (this.startTimeM != "") {
      this.reqM.startTimeBegin = this.startTimeM;
    }
    if (this.stopTimeM != "") {
      this.reqM.startTimeEnd = this.stopTimeM;
    }
  }
  private setReqR() {
    this.reqR = {
      page: 1,
      limit: this.limit,
    };
    this.robotNameR = "";
    for (let index = 0; index < this.setListR[0].list.length; index++) {
      const element = this.setListR[0].list[index];
      if (element.isSelect) {
        this.robotNameR += element.text + ",";
      }
    }
    if (this.robotNameR != "") {
      this.reqR.robotName = this.robotNameR.substring(
        0,
        this.robotNameR.length - 1
      );
    }
    if (this.startTimeR != "") {
      this.reqR.startTimeBegin = this.startTimeR;
    }
    if (this.stopTimeR != "") {
      this.reqR.startTimeEnd = this.stopTimeR;
    }
  }
  private  newFault() {
    // let refresh = localStorage.getItem("refresh");
    localStorage.setItem("refresh", "true");
    //Toast(globalData.exportFileUrl + this.$t("exportTaskStart").toString());
    console.log("newFau11111111lt")
    if(this.active == 0) {
      this.$router.push({
        path: "/newFault",
      });
    } else if(this.active == 1) {
      this.$router.push({
        path: "/newMaintain",
      });
    } else if (this.active == 2) {
      this.$router.push({
        path: "/newRepair",
      });
    }
  }
}
